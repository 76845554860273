import {useSorting} from '@hconnect/common/hooks'
import {getMaintainRcfaStoppageListUrl, getTranslationKey} from '@hconnect/common/utils'
import {customThemeConstants, expandableTableHeaderCellSx} from '@hconnect/uikit/src/lib2'
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TableSortLabel,
  SortDirection,
  Theme,
  Box
} from '@mui/material'
import {SxProps} from '@mui/system'
import {orderBy, isEqual} from 'lodash'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {RcfaComplianceTableData} from '../types'

import {
  getComplianceScorePercentage,
  HeaderWithTooltip,
  RCFA_COMPLIANCE_TABLE_KEYS,
  RcfaComplianceTableKey,
  renderRcfaComplianceTableCell,
  RcfaComplianceTableKeys
} from './RcfaComplianceTableRow'

type PlantTableProps = {
  data: RcfaComplianceTableData[]
  translationPrefix?: string
}

type PlantTableHeaderCellProps = {
  columnKey: RcfaComplianceTableKey
  orderByKey?: RcfaComplianceTableKey
  sortDir: SortDirection
  onSortClick: (key: RcfaComplianceTableKey) => void
  sx?: SxProps<Theme>
  children?: React.ReactNode
}

const SortableHeaderCell = ({
  columnKey,
  orderByKey,
  sortDir,
  onSortClick,
  children,
  sx
}: PlantTableHeaderCellProps) => (
  <TableCell sortDirection={orderByKey === columnKey && sortDir} sx={sx}>
    <TableSortLabel
      active={sortDir && orderByKey === columnKey}
      direction={orderByKey === columnKey && sortDir ? sortDir : undefined}
      onClick={() => onSortClick(columnKey)}
    >
      {children}
    </TableSortLabel>
  </TableCell>
)

const sortPlants = (
  plants: RcfaComplianceTableData[],
  orderByKey: RcfaComplianceTableKey,
  sortDir: SortDirection
): RcfaComplianceTableData[] => {
  if (orderByKey === RcfaComplianceTableKeys.COMPLIANCE_SCORE_BAR) {
    plants.sort((a, b) => {
      // if totalMandatoryCount is 0, it should be always on the bottom
      if (a.rcfaCompliance?.totalMandatoryCount === 0) {
        return 1
      }
      if (b.rcfaCompliance?.totalMandatoryCount === 0) {
        return -1
      }

      const aScore = getComplianceScorePercentage(
        a.rcfaCompliance?.completedCount ?? 0,
        a.rcfaCompliance?.totalMandatoryCount ?? 0
      )
      const bScore = getComplianceScorePercentage(
        b.rcfaCompliance?.completedCount ?? 0,
        b.rcfaCompliance?.totalMandatoryCount ?? 0
      )

      return sortDir === 'asc' ? aScore - bScore : bScore - aScore
    })

    return plants
  }
  if (orderByKey === RcfaComplianceTableKeys.LOCATION) {
    return orderBy(plants, ['name'], [sortDir])
  }
  // TODO: This will be enabled in the follow-up stories
  // if (orderByKey === RcfaComplianceTableKeys.UNRESOLVED_TASKS) {
  //   return orderBy(plants, ['complianceData.unresolvedRcfaCount'], [sortDir])
  // }
  // if (orderByKey === RcfaComplianceTableKeys.OVERDUE_TASKS) {
  //   return orderBy(plants, ['complianceData.overdueRcfaCount'], [sortDir])
  // }
  return orderBy(plants, [orderByKey], [sortDir])
}

const RcfaCompliancePlantListComponent: React.FC<PlantTableProps> = ({data, translationPrefix}) => {
  const {t} = useTranslation()

  const {sortedList, onSortClick, orderByKey, sortDir} = useSorting<
    RcfaComplianceTableData,
    RcfaComplianceTableKey
  >({
    initialSortDir: false,
    initialOrderByKey: RcfaComplianceTableKeys.LOCATION,
    data,
    sort: sortPlants
  })

  return (
    <TableContainer data-test-id="rcfa-compliance-plants-table">
      <Table>
        <TableHead>
          <TableRow>
            <SortableHeaderCell
              columnKey={RcfaComplianceTableKeys.LOCATION}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              <Box sx={expandableTableHeaderCellSx(true)}>
                {t(getTranslationKey('rcfaCompliance.label.location', translationPrefix))}
              </Box>
            </SortableHeaderCell>
            <SortableHeaderCell
              columnKey={RcfaComplianceTableKeys.COMPLIANCE_SCORE_BAR}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
            >
              <HeaderWithTooltip
                headerText={`${t(
                  getTranslationKey('rcfaCompliance.label.rcfas', translationPrefix)
                )} ${t(getTranslationKey('rcfaCompliance.label.completed', translationPrefix))}/${t(
                  getTranslationKey('rcfaCompliance.label.mandadotory', translationPrefix)
                )}`}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.rcfaScore', translationPrefix)
                )}
              />
            </SortableHeaderCell>
            <TableCell />
            {/* TODO: This will be enabled in the follow-up stories */}
            {/* <SortableHeaderCell
              columnKey={RcfaComplianceTableKeys.UNRESOLVED_TASKS}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
              sx={{textAlign: 'right'}}
            >
              <HeaderWithTooltip
                headerText={t(
                  getTranslationKey('rcfaCompliance.label.unresolvedTasks', translationPrefix)
                )}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.unresolvedTasks', translationPrefix)
                )}
              />
            </SortableHeaderCell>
            <SortableHeaderCell
              columnKey={RcfaComplianceTableKeys.OVERDUE_TASKS}
              orderByKey={orderByKey}
              sortDir={sortDir}
              onSortClick={onSortClick}
              sx={{textAlign: 'right'}}
            >
              <HeaderWithTooltip
                headerText={t(
                  getTranslationKey('rcfaCompliance.label.overdueTasks', translationPrefix)
                )}
                tooltipText={t(
                  getTranslationKey('rcfaCompliance.tooltip.overdueTasks', translationPrefix)
                )}
              />
            </SortableHeaderCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedList.map((plant, index) => (
            <TableRow
              key={plant.id}
              sx={{
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor: customThemeConstants().palette.primaryBackgroundColor
                }
              }}
              onClick={() => {
                const url = getMaintainRcfaStoppageListUrl(plant.id)
                // navigate to different domain
                window.location.assign(url)
              }}
              data-test-id={`plant-table-row-${plant.id}`}
            >
              {RCFA_COMPLIANCE_TABLE_KEYS.map((key) => (
                <TableCell key={key}>{renderRcfaComplianceTableCell(plant, index, key)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const RcfaCompliancePlantList = React.memo(RcfaCompliancePlantListComponent, isEqual)
