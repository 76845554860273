import {Loader} from '@hconnect/uikit/src/lib2'
import {Navigate} from 'react-router'

import {SUPPORTED_MAP_AREAS} from '../../consts'
import {useHierarchyNavAccess} from '../../hooks'
import {HierarchyRouteSegment} from '../../routing'

export const ReliabilityEngineerDashboardLandingPage = () => {
  const {isInitialLoading, allowedAreas, getPlantsByAreaId} = useHierarchyNavAccess(
    'VIEW_RELIABILITY_ENGINEER_DASHBOARD'
  )
  if (isInitialLoading) {
    return <Loader />
  }

  if (allowedAreas.length === 1) {
    const accessibleArea = allowedAreas[0]
    const accessibleAreaPlants = getPlantsByAreaId(accessibleArea)

    if (accessibleAreaPlants.length === 1) {
      return (
        <Navigate
          to={`${HierarchyRouteSegment.AREA}/${accessibleArea}/${HierarchyRouteSegment.PLANT}/${accessibleAreaPlants[0].id}`}
        />
      )
    }

    if (accessibleAreaPlants.length > 1) {
      if (SUPPORTED_MAP_AREAS.includes(accessibleArea)) {
        return <Navigate to={`${HierarchyRouteSegment.AREA}/${accessibleArea}`} />
      }

      return <Navigate to={HierarchyRouteSegment.PLANT} />
    }
  }

  if (allowedAreas.length > 1) {
    return <Navigate to={HierarchyRouteSegment.AREA} />
  }

  // User does not have access to any location
  return <Navigate to="/" />
}
