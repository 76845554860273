import {PlantViewOptions} from '@hconnect/common/consts'
import {CardBox} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import {useMemo} from 'react'

import {useTranslationPrefix} from '../../shared/hooks'
import {
  DataContentWrapper,
  RcfaComplianceHierarchyTable,
  RcfaCompliancePlantList
} from '../components'
import {useRcfaCompliance} from '../hooks'
import {
  convertToLocationNodes,
  mapToRcfaComplianceHierarchyTable,
  mapToRcfaCompliancePlantList
} from '../mappers'

type Props = {
  plantView: string
  timeRange: string
  timezone: string
  getIsExpanded: (id: string) => boolean
  toggleExpandCollapse: (id: string) => void
}

export const RcfaComplianceContainer = (props: Props) => {
  const {timeRange, timezone, plantView} = props
  const {hrocPrefix} = useTranslationPrefix()
  const {data, isInitialLoading, isError, isRefetching, refetch} = useRcfaCompliance(
    {
      timezone,
      timeRange
    },
    {select: convertToLocationNodes}
  )
  const hierarchyTableData = useMemo(() => mapToRcfaComplianceHierarchyTable(data ?? []), [data])
  const plantListData = useMemo(() => mapToRcfaCompliancePlantList(data ?? []), [data])

  return (
    <CardBox sx={{flex: 1}}>
      <DataContentWrapper
        data={data}
        showLoader={isInitialLoading}
        isError={isError}
        isRefetching={isRefetching}
        retryFunction={refetch}
        progressSx={{color: 'primary.main'}}
      >
        <Box display={plantView === PlantViewOptions.PLANT_LIST ? 'initial' : 'none'}>
          <RcfaCompliancePlantList data={plantListData} translationPrefix={hrocPrefix} />
        </Box>
        <Box display={plantView === PlantViewOptions.REGIONAL_STRUCTURE ? 'initial' : 'none'}>
          <RcfaComplianceHierarchyTable
            data={hierarchyTableData}
            translationPrefix={hrocPrefix}
            getIsExpanded={props.getIsExpanded}
            toggleExpandCollapse={props.toggleExpandCollapse}
          />
        </Box>
      </DataContentWrapper>
    </CardBox>
  )
}
