import {PlantViewToggle} from '@hconnect/common/components'
import {PlantViewOption, PlantViewOptions} from '@hconnect/common/consts'
import {TimeZone} from '@hconnect/uikit'
import {
  buttonOnBlueSx,
  DateRangePicker,
  ExpandCollapseButton,
  mergeSxs
} from '@hconnect/uikit/src/lib2'
import {Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {useTranslationPrefix} from '../../shared/hooks'
import {getRcfaComplianceQuickSelectionItems} from '../utils'

type Props = {
  timeRange: string
  setTimeRange: (param: string) => void
  allExpanded: boolean
  toggleExpandCollapseAll: () => void
  plantView: PlantViewOption
  setPlantView: (value: PlantViewOption) => void
  timezone: TimeZone
}

export const RcfaComplianceHeaderActions = (props: Props) => {
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()
  const {timezone} = props

  return (
    <Stack direction="row" spacing={1} alignItems="center" flexWrap="wrap">
      <DateRangePicker
        timeRangeParam={props.timeRange}
        onTimeRangeParamChange={props.setTimeRange}
        singleDateSwitchLabel={t('quickSelection.pickSingleDate')}
        selectionItems={getRcfaComplianceQuickSelectionItems(t, timezone)}
        timezone={timezone}
      />
      {props.plantView === PlantViewOptions.REGIONAL_STRUCTURE && (
        <ExpandCollapseButton
          sx={mergeSxs(buttonOnBlueSx, {
            minWidth: {xs: '100%', sm: '150px'}
          })}
          collapseLabel={t(`${hrocPrefix}.button.collapseAll`)}
          expandLabel={t(`${hrocPrefix}.button.expandAll`)}
          expanded={props.allExpanded}
          onClick={props.toggleExpandCollapseAll}
          data-test-id="expand-collapse-button"
        />
      )}
      <PlantViewToggle
        value={props.plantView}
        onChange={props.setPlantView}
        translationPrefix={hrocPrefix}
      />
    </Stack>
  )
}
