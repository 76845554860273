import {customThemeConstants, Flag, GaugeBar} from '@hconnect/uikit/src/lib2'
import {InfoOutlined} from '@mui/icons-material'
import {Box, Stack, SxProps, Tooltip, Typography} from '@mui/material'
import React from 'react'

import {RcfaComplianceTableData} from '../types'

export const RcfaComplianceTableKeys = {
  LOCATION: 'location',
  COMPLIANCE_SCORE_BAR: 'complianceScoreBar',
  COMPLIANCE_SCORE: 'complianceScore'
  // TODO: This will be enabled in the follow-up stories
  // UNRESOLVED_TASKS: 'unresolvedTasks',
  // OVERDUE_TASKS: 'overdueTasks'
} as const

export type RcfaComplianceTableKey =
  (typeof RcfaComplianceTableKeys)[keyof typeof RcfaComplianceTableKeys]

export const RCFA_COMPLIANCE_TABLE_KEYS = Object.values(RcfaComplianceTableKeys)

export const HeaderWithTooltip = ({
  headerText,
  tooltipText,
  sx
}: {
  headerText: string
  tooltipText: string
  sx?: SxProps
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      ...sx
    }}
  >
    {headerText}
    <Tooltip
      title={
        <Stack spacing={1}>
          <Typography fontWeight="bold">{headerText}</Typography>
          <Typography>{tooltipText}</Typography>
        </Stack>
      }
    >
      <InfoOutlined sx={{ml: 1}} />
    </Tooltip>
  </Box>
)

const LocationCellContent = ({
  name,
  countryCode,
  dataTestId
}: {
  name: string
  countryCode?: string
  dataTestId: string
}) => (
  <Typography component="span" data-test-id={dataTestId}>
    {countryCode && (
      <Flag
        countryCode={countryCode}
        alt={name}
        height={18}
        sx={{
          verticalAlign: 'text-bottom',
          mr: 1
        }}
      />
    )}
    {name}
  </Typography>
)

const tableCellDataTestIdGenerator = (id: string, columnKey: string) =>
  `table-cell-${id}-${columnKey}`

export const renderRcfaComplianceTableCell = <T extends RcfaComplianceTableData>(
  item: T,
  columnIndex: number,
  columnKey: string
): React.ReactNode => {
  const completed = item.rcfaCompliance?.completedCount ?? 0
  const mandatory = item.rcfaCompliance?.totalMandatoryCount ?? 0
  const dataTestId = tableCellDataTestIdGenerator(item.id, columnKey)

  switch (columnKey) {
    case RcfaComplianceTableKeys.LOCATION: {
      return (
        <LocationCellContent
          name={item.name}
          countryCode={item.countryCode}
          dataTestId={dataTestId}
        />
      )
    }
    case RcfaComplianceTableKeys.COMPLIANCE_SCORE_BAR:
      return (
        <GaugeBar
          value={completed}
          maxValue={mandatory}
          color={complianceScoreColor(completed, mandatory)}
          customSubtitle={true}
        />
      )
    case RcfaComplianceTableKeys.COMPLIANCE_SCORE:
      return <ComplianceScore value={completed} maxValue={mandatory} dataTestId={dataTestId} />
    // TODO: This will be enabled in the follow-up stories
    // case RcfaComplianceTableKeys.UNRESOLVED_TASKS:
    //   return <Typography align="right">{item.rcfaCompliance?.unresolvedRcfaCount ?? 0}</Typography>
    // case RcfaComplianceTableKeys.OVERDUE_TASKS:
    //   return <Typography align="right">{item.rcfaCompliance?.overdueRcfaCount ?? 0}</Typography>
  }

  return item[columnKey]
}

const complianceScoreColor = (value: number, maxValue: number): string => {
  if (maxValue === 0) {
    return customThemeConstants().palette.disabledBackgroundColor
  }

  const percentage = (value / maxValue) * 100

  if (percentage >= 85) {
    return 'primary.main'
  }

  if (percentage >= 50) {
    return 'warning.main'
  }

  return 'error.main'
}

const ComplianceScore = ({
  value,
  maxValue,
  dataTestId
}: {
  value: number
  maxValue: number
  dataTestId: string
}) => {
  const showPercentage = maxValue > 0

  return (
    <Typography fontWeight={600} data-test-id={dataTestId}>
      {value}/
      <Typography component="span" fontSize="inherit" color="text.secondary">
        {maxValue}
      </Typography>
      {showPercentage && ` (${getComplianceScorePercentage(value, maxValue)}%)`}
    </Typography>
  )
}

export function getComplianceScorePercentage(value: number, maxValue: number): number {
  return Number(toPercent(value, maxValue).toFixed(0))
}

function toPercent(a: number, b: number): number {
  if (b === 0) {
    return 0
  }

  return (a / b) * 100
}
