import {type Permission} from '@hconnect/apiclient'
import {HPRODUCE_HROC} from '@hconnect/common/hproduce/config'
import {NavItem} from '@hconnect/uikit/src/lib2'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {generatePath, useParams} from 'react-router-dom'

import {checkFeatureFlag} from '../../shared/helpers'
import {useTranslationPrefix} from '../../shared/hooks'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission,
  hasViewProcessEngineerDashboardPermission,
  hasViewOptimizationSpecialistDashboardPermission,
  hasRcfaCompliancePagePermission
} from '../utils/permissions'

import {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD,
  RCFA_COMPLIANCE
} from './routes'

type PathParameter = {
  plantId?: string
}

const MIN_NAV_ITEMS_FOR_GROUPING = 2

export const useGetHrocLinks = ({permissions}: {permissions: Permission[]}): NavItem[] => {
  const {plantId} = useParams<PathParameter>()
  const {t} = useTranslation()
  const {hrocPrefix} = useTranslationPrefix()

  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const isOptimizationSpecialistDashboardEnabled = checkFeatureFlag(
    'optimizationSpecialistDashboard'
  )
  const userHasChecklistsPermission = useMemo(
    () => hasChecklistsPermission(permissions),
    [permissions]
  )
  const userHasReliabilityEngineerPermission = useMemo(
    () => hasViewReliabilityEngineerDashboardPermission(permissions),
    [permissions]
  )
  const isHierarchyReliabilityEngineerEnabled = checkFeatureFlag(
    'hierarchyReliabilityEngineerDashboard'
  )

  const userHasProcessEngineerPermission = useMemo(
    () => hasViewProcessEngineerDashboardPermission(permissions),
    [permissions]
  )

  const userHasOptimizationSpecialistPermission = useMemo(
    () => hasViewOptimizationSpecialistDashboardPermission(permissions),
    [permissions]
  )

  const isRcfaComplianceEnabled = checkFeatureFlag('rcfaCompliance')
  const userHasRcfaCompliancePermission = useMemo(
    () => hasRcfaCompliancePagePermission(permissions),
    [permissions]
  )

  const checklistNavItem: NavItem = {
    url: plantId ? generatePath(PLANT_CHECKLISTS_TEMPLATES, {plantId}) : CHECKLISTS,
    label: t(`${hrocPrefix}.navPages.checklists`)
  }

  const reliabilityEngineerDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_RELIABILITY_ENGINEER_DASHBOARD, {plantId})
      : RELIABILITY_ENGINEER_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.reliabilityEngineerDashboard`)
  }
  const hierarchyReliabilityEngineerDashboardNavItem: NavItem = {
    url: generatePath(RELIABILITY_ENGINEER_DASHBOARD),
    label: t(`${hrocPrefix}.navPages.reliabilityEngineerDashboard`),
    dataTestId: 'hierarchy-reliability-engineer-dashboard-nav-item'
  }

  const processEngineerDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_PROCESS_ENGINEER_DASHBOARD, {plantId})
      : PROCESS_ENGINEER_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.processEngineerDashboard`),
    dataTestId: 'process-engineer-dashboard-nav-item'
  }

  const optimizationSpecialistDashboardNavItem: NavItem = {
    url: plantId
      ? generatePath(PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD, {plantId})
      : OPTIMIZATION_SPECIALIST_DASHBOARD,
    label: t(`${hrocPrefix}.navPages.optimizationSpecialistDashboard`),
    dataTestId: 'optimization-specialist-dashboard-nav-item'
  }

  const rfcaComplianceNavItem: NavItem = {
    url: RCFA_COMPLIANCE,
    label: t(`${hrocPrefix}.navPages.rcfaCompliance`),
    dataTestId: 'rcfa-compliance-nav-item'
  }

  const navItems: NavItem[] = []

  if (areChecklistsEnabled && userHasChecklistsPermission) {
    navItems.push(checklistNavItem)
  }
  if (userHasReliabilityEngineerPermission) {
    if (isHierarchyReliabilityEngineerEnabled) {
      navItems.push(hierarchyReliabilityEngineerDashboardNavItem)
    } else {
      navItems.push(reliabilityEngineerDashboardNavItem)
    }
  }
  if (userHasProcessEngineerPermission) {
    navItems.push(processEngineerDashboardNavItem)
  }
  if (isOptimizationSpecialistDashboardEnabled && userHasOptimizationSpecialistPermission) {
    navItems.push(optimizationSpecialistDashboardNavItem)
  }
  if (isRcfaComplianceEnabled && userHasRcfaCompliancePermission) {
    navItems.push(rfcaComplianceNavItem)
  }

  if (navItems.length >= MIN_NAV_ITEMS_FOR_GROUPING) {
    return [
      {
        url: HPRODUCE_HROC,
        label: t(`${hrocPrefix}.navPages.hroc`),
        subItems: navItems,
        isDisabled: true, // we do not provide a page for the main HROC nav item
        dataTestId: 'hroc-nav-item'
      }
    ]
  }

  return navItems
}
