import {PermissionType} from '@hconnect/apiclient'
import {PlantSelect} from '@hconnect/common/components'
import {PlantSelectOption} from '@hconnect/common/hproduce/types'
import {getPlantName} from '@hconnect/common/hproduce/whiteListCheck'
import {PageContainer} from '@hconnect/uikit/src/lib2'
import {FC} from 'react'
import {generatePath} from 'react-router'
import {Navigate} from 'react-router-dom'

import {useTranslationPrefix} from '../../shared/hooks/useTranslationPrefix'
import {usePlantsByPermission} from '../hooks/usePlantsByPermission'

type PerformancePlantSelectProps = {
  path: string
  permission: PermissionType
}

export const PlantSelectByPermission: FC<PerformancePlantSelectProps> = ({path, permission}) => {
  const {hrocPrefix} = useTranslationPrefix()
  const {plantIds} = usePlantsByPermission(permission)

  const plantOptions: PlantSelectOption[] = plantIds.map((plantId) => {
    return {
      plantId,
      plantName: getPlantName(plantId),
      url: generatePath(path, {plantId})
    }
  })

  if (plantOptions.length === 1) {
    return <Navigate to={plantOptions[0].url} />
  }

  return (
    <PageContainer>
      <PlantSelect plants={plantOptions} translationPrefix={hrocPrefix} />
    </PageContainer>
  )
}
