import {Permission} from '@hconnect/apiclient'
import {Route, Outlet} from 'react-router-dom'

import {checkFeatureFlag} from '../shared/helpers'
import {
  ConfigProviderWrapper,
  PermissionsContextProvider,
  TranslationPrefixProvider
} from '../shared/hooks'
import {DefaultShell} from '../shared/layouts'
import {Context} from '../shared/types'

import {UserContextProvider} from './hooks'
import {
  AreaPlantSelectByPermission,
  AreaSelectByPermission,
  AreaReliabilityEngineerDashboard,
  ChecklistsTemplatesPage,
  OptimizationSpecialistDashboard,
  PlantChecklistPage,
  PlantSelectByPermission,
  ProcessEngineerDashboard,
  RcfaCompliancePage,
  ReliabilityEngineerDashboard,
  ReliabilityEngineerDashboardLandingPage
} from './pages'
import {PlantPermissionGuardedRoute} from './routing'
import {
  CHECKLISTS,
  OPTIMIZATION_SPECIALIST_DASHBOARD,
  PLANT_CHECKLISTS_TEMPLATES,
  PLANT_CHECKLIST,
  RELIABILITY_ENGINEER_DASHBOARD,
  PLANT_RELIABILITY_ENGINEER_DASHBOARD,
  PROCESS_ENGINEER_DASHBOARD,
  PLANT_PROCESS_ENGINEER_DASHBOARD,
  PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD,
  RCFA_COMPLIANCE,
  HierarchyRouteSegment
} from './routing/routes'
import {
  hasChecklistsPermission,
  hasViewReliabilityEngineerDashboardPermission,
  hasViewProcessEngineerDashboardPermission,
  hasViewOptimizationSpecialistDashboardPermission,
  hasRcfaCompliancePagePermission
} from './utils'

const Wrapper: React.FC<{
  context: Context
  permissions: Permission[]
  children: React.ReactNode
}> = ({children, context, permissions}) => (
  <TranslationPrefixProvider>
    <UserContextProvider initialState={context.user}>
      <PermissionsContextProvider initialState={permissions}>
        <DefaultShell context={context}>{children}</DefaultShell>
      </PermissionsContextProvider>
    </UserContextProvider>
  </TranslationPrefixProvider>
)

export const getHrocRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => {
  const areChecklistsEnabled = checkFeatureFlag('checklists')
  const isOptimizationSpecialistDashboardEnabled = checkFeatureFlag(
    'optimizationSpecialistDashboard'
  )
  const isRcfaComplianceDashboardEnabled = checkFeatureFlag('rcfaCompliance')
  const isHierarchyReliabilityEngineerDashboardEnabled = checkFeatureFlag(
    'hierarchyReliabilityEngineerDashboard'
  )

  return (
    <>
      {areChecklistsEnabled && hasChecklistsPermission(permissions) && (
        <>
          <Route
            key="checklists-plant-select"
            path={CHECKLISTS}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_CHECKLISTS_TEMPLATES}
                  permission="VIEW_CHECKLISTS"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLISTS_TEMPLATES}
            key="checklists-templates"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_CHECKLISTS"
                  plantSelectPath={CHECKLISTS}
                >
                  <ConfigProviderWrapper>
                    <ChecklistsTemplatesPage />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
          <Route
            path={PLANT_CHECKLIST}
            key="plant-checklist"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantChecklistPage />
              </Wrapper>
            }
          />
        </>
      )}
      {hasViewProcessEngineerDashboardPermission(permissions) && (
        <>
          <Route
            key="process-engineer-dashboard-plant-select"
            path={PROCESS_ENGINEER_DASHBOARD}
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantSelectByPermission
                  path={PLANT_PROCESS_ENGINEER_DASHBOARD}
                  permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
                />
              </Wrapper>
            }
          />
          <Route
            path={PLANT_PROCESS_ENGINEER_DASHBOARD}
            key="process-engineer-dashboard"
            element={
              <Wrapper context={context} permissions={permissions}>
                <PlantPermissionGuardedRoute
                  permission="VIEW_PROCESS_ENGINEER_DASHBOARD"
                  plantSelectPath={PROCESS_ENGINEER_DASHBOARD}
                >
                  <ConfigProviderWrapper>
                    <ProcessEngineerDashboard />
                  </ConfigProviderWrapper>
                </PlantPermissionGuardedRoute>
              </Wrapper>
            }
          />
        </>
      )}

      {hasViewReliabilityEngineerDashboardPermission(permissions) && (
        <>
          {isHierarchyReliabilityEngineerDashboardEnabled ? (
            getReliabilityEngineerRoutes({context, permissions})
          ) : (
            <>
              <Route
                key="reliability-engineer-dashboard-plant-select"
                path={RELIABILITY_ENGINEER_DASHBOARD}
                element={
                  <Wrapper context={context} permissions={permissions}>
                    <PlantSelectByPermission
                      path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
                      permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
                    />
                  </Wrapper>
                }
              />
              <Route
                path={PLANT_RELIABILITY_ENGINEER_DASHBOARD}
                key="reliability-engineer-dashboard"
                element={
                  <Wrapper context={context} permissions={permissions}>
                    <PlantPermissionGuardedRoute
                      permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
                      plantSelectPath={RELIABILITY_ENGINEER_DASHBOARD}
                    >
                      <ConfigProviderWrapper>
                        <ReliabilityEngineerDashboard />
                      </ConfigProviderWrapper>
                    </PlantPermissionGuardedRoute>
                  </Wrapper>
                }
              />
            </>
          )}
        </>
      )}

      {isOptimizationSpecialistDashboardEnabled &&
        hasViewOptimizationSpecialistDashboardPermission(permissions) && (
          <>
            <Route
              key="optimization-specialist-dashboard-plant-select"
              path={OPTIMIZATION_SPECIALIST_DASHBOARD}
              element={
                <Wrapper context={context} permissions={permissions}>
                  <PlantSelectByPermission
                    path={PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD}
                    permission="VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD"
                  />
                </Wrapper>
              }
            />
            <Route
              path={PLANT_OPTIMIZATION_SPECIALIST_DASHBOARD}
              key="optimization-specialist-dashboard"
              element={
                <Wrapper context={context} permissions={permissions}>
                  <PlantPermissionGuardedRoute
                    permission="VIEW_OPTIMIZATION_SPECIALIST_DASHBOARD"
                    plantSelectPath={OPTIMIZATION_SPECIALIST_DASHBOARD}
                  >
                    <ConfigProviderWrapper>
                      <OptimizationSpecialistDashboard />
                    </ConfigProviderWrapper>
                  </PlantPermissionGuardedRoute>
                </Wrapper>
              }
            />
          </>
        )}

      {isRcfaComplianceDashboardEnabled && hasRcfaCompliancePagePermission(permissions) && (
        <Route
          key="rcfa-complience-dashboard"
          path={RCFA_COMPLIANCE}
          element={
            <Wrapper context={context} permissions={permissions}>
              <RcfaCompliancePage />
            </Wrapper>
          }
        />
      )}
    </>
  )
}

const getReliabilityEngineerRoutes = ({
  context,
  permissions
}: {
  context: Context
  permissions: Permission[]
}) => [
  <Route
    key="reliability-engineer-dashboard-routes"
    path={RELIABILITY_ENGINEER_DASHBOARD}
    element={
      <Wrapper context={context} permissions={permissions}>
        <Outlet />
      </Wrapper>
    }
  >
    <Route index element={<ReliabilityEngineerDashboardLandingPage />} />
    <Route path={HierarchyRouteSegment.AREA}>
      <Route
        index
        element={
          <AreaSelectByPermission
            path={`${HierarchyRouteSegment.AREA}/:areaId`}
            permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
          />
        }
      />
      <Route index path=":areaId" element={<AreaReliabilityEngineerDashboard />} />
      <Route path=":areaId">
        <Route path={HierarchyRouteSegment.PLANT}>
          <Route
            index
            element={
              <AreaPlantSelectByPermission
                path={`${HierarchyRouteSegment.PLANT}/:plantId`}
                permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
              />
            }
          />
          <Route
            path=":plantId"
            element={
              <PlantPermissionGuardedRoute
                permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
                plantSelectPath={'..'}
              >
                <ConfigProviderWrapper>
                  <ReliabilityEngineerDashboard />
                </ConfigProviderWrapper>
              </PlantPermissionGuardedRoute>
            }
          />
        </Route>
      </Route>
    </Route>
    <Route path={HierarchyRouteSegment.PLANT}>
      <Route
        index
        element={
          <PlantSelectByPermission
            path={`${HierarchyRouteSegment.PLANT}/:plantId`}
            permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
          />
        }
      />
      <Route
        path=":plantId"
        element={
          <PlantPermissionGuardedRoute
            permission="VIEW_RELIABILITY_ENGINEER_DASHBOARD"
            plantSelectPath={'..'}
          >
            <ConfigProviderWrapper>
              <ReliabilityEngineerDashboard />
            </ConfigProviderWrapper>
          </PlantPermissionGuardedRoute>
        }
      />
    </Route>
  </Route>
]
